<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div>
    <div class="sld_order_list">
      <MemberTitle :memberTitle="L['单次计划']"></MemberTitle>
      <div class="container">
        <h3>{{ L["单次计划"] }}</h3>

        <div class="headerSearch3">
          <el-form
            ref="ruleFormRef"
            :model="param"
            :inline="true"
            class="demo-form-inline"
          >
            <!--<el-form-item label="采购来源" prop="source">
              <el-select v-model="param.source" placeholder="" style="width:210px ">
                <el-option label="用友" value="0" />
                <el-option
                  :label="platform == 1 ? '大石工采' : '大石集采'"
                  value="1"
                />
              </el-select>
            </el-form-item>-->
            <el-form-item label="采购单号" prop="planCode">
              <el-input v-model="param.planCode" style="width: 195px" />
            </el-form-item>
            <el-form-item
              label="请购单号"
              prop="planCode"
              style="margin-left: 10px"
              v-if="platform == 2"
            >
              <el-input v-model="param.bizBillCode" style="width: 195px" />
            </el-form-item>
            <el-form-item label="创建人" prop="prayName">
              <el-input v-model="param.prayName" style="width: 195px" />
            </el-form-item>
            <!--<div style="width: 195px"></div>-->
            <el-form-item label="计划状态" prop="state">
              <el-select
                v-model="param.state"
                placeholder=""
                style="width: 195px"
              >
                <el-option label="待报价" value="0" />
                <el-option label="报价中" value="1" />
                <el-option label="已报价" value="2" />
                <el-option label="已关闭" value="3" />
              </el-select>
            </el-form-item>
            <el-form-item
              label="商品名称"
              prop="goodsName"
              :style="platform == 2?'margin-left: 10px':''"
            >
              <el-input v-model="param.goodsName" style="width: 195px" />
            </el-form-item>
            <el-form-item label="创建日期" prop="time">
              <el-date-picker
                v-model="param.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                style="width: 240px"
              />
            </el-form-item>
            <!--<el-form-item>-->
            <div class="searchBtn">
              <div class="button" @click="onSubmit(ruleFormRef)">搜索</div>
              <el-button @click="resetForm(ruleFormRef)">重置</el-button>
            </div>
            <!--</el-form-item>-->
          </el-form>
        </div>
        <div></div>

        <div class="sld_order_button">
          <div class="button2" @click="addPlan">新增</div>
        </div>
        <div class="sld_List">
          <!--<el-table
            :data="tableData.data"
            border
            max-width="250"
            header-cell-style="background:#FAFAFAFF;"
          >
            <el-table-column
              type="index"
              label="序号"
              width="50"
              align="center"
            />
            <el-table-column
              prop="planCode"
              label="采购单号"
              width="180"
              align="center"
            />
            <el-table-column
              prop="source"
              label="采购来源"
              width="100"
              align="center"
            >
              <template #default="scope">
                
                <img
                  style="width: 23px; height: 17px"
                  v-if="scope.row.source == 0"
                  src="@/assets/yongyouLogo.png"
                />
                <img
                  style="width: 48px; height: 10px"
                  v-if="scope.row.source == 1"
                  src="@/assets/dashiLogo.png"
                />
               
              </template>
            </el-table-column>
            <el-table-column prop="type" label="采购类型" align="center">
              <template #default="scope">
                <span v-if="scope.row.type == 0">单次采购</span>
                <span v-if="scope.row.type == 1">周期采购</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="goodsNum"
              label="商品数量"
              max-height="60"
              align="center"
            />
            <el-table-column
              prop="prayName"
              label="创建人"
              align="center"
              width="130px"
            >
              <template #default="scope">
                <p>{{ scope.row.prayName }}</p>
                <p>{{ scope.row.operator }}</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center"
            />
            <el-table-column prop="state" label="状态" align="center">
              <template #default="scope">
                <el-tag
                  v-if="scope.row.state == 0"
                  class="ml-2"
                  effect="light"
                >
                  待报价
                </el-tag>
                <el-tag
                  v-if="scope.row.state == 1"
                  type="success"
                  class="ml-2"
                  effect="light"
                >
                  报价中
                </el-tag>
                <el-tag
                  v-if="scope.row.state == 2"
                  class="ml-2"
                  type="warning"
                  effect="light"
                >
                  已报价
                </el-tag>

                <el-tag
                  v-if="scope.row.state == 3"
                  type="danger"
                  class="ml-2"
                  effect="light"
                >
                  已关闭
                </el-tag>
                <el-tag
                  v-if="scope.row.state == 4"
                  type="info"
                  class="ml-2"
                  effect="light"
                >
                  部分报价
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作">
              <template #default="scope">
                <p
                  class="look"
                  type="primary"
                  size="small"
                  @click="toDetail(scope.row.planCode)"
                  link
                >
                  查看
                </p>
              </template>
            </el-table-column>
          </el-table>-->
          <div
            class="order_item"
            v-for="(orderItem, index) in tableData.data"
            :key="index"
          >
            <div class="title flex_row_between_center">
              <div class="flex_row_start_center">
                <div class="left">
                  <img
                    v-if="orderItem.source == 0"
                    src="@/assets/yongyou.png"
                  />

                  <img
                    v-if="orderItem.source == 1"
                    src="@/assets/dashiLogo.png"
                  />
                  <img
                    v-if="orderItem.source == 2"
                    src="@/assets/dashijicai@2x.png"
                  />
                </div>
                <div>
                  <span class="titleColor">创建时间</span>:{{
                    orderItem.createTime
                  }}
                </div>
                <div class="order_num">
                  <!--@click="goDetail(orderItem.memberOrderCode)"-->
                  <span class="titleColor">采购单号</span>:{{
                    orderItem.planCode
                  }}
                </div>
                <div class="order_num" v-if="orderItem.source == 0">
                  <!--@click="goDetail(orderItem.memberOrderCode)"-->
                  <span class="titleColor">请购单号</span>:
                  {{ orderItem.bizBillCode }}
                </div>
                <div
                  class="payment_date"
                  style="display: flex; align-items: center"
                >
                  <span class="titleColor">创建人</span>:
                  <p>{{ orderItem.prayName }}</p>
                  <!--<p>{{ orderItem.operator }}</p>-->
                </div>
                <div class="orderPayTyp_eName">
                  <span>{{ orderItem.type }}</span>
                  <!--<span v-if="orderItem.type == 1">周期采购</span>-->
                </div>
              </div>
              <div v-if="orderItem.isFillIn == 0" class="hintTags">
                未填我的报价
              </div>
            </div>
            <div class="good_info_con flex_row_start_center">
              <div class="good_info flex_column_center_start">
                <div class="item flex_row_start_center">
                  <div class="good flex_row_start_center">
                    <div class="right">
                      <div
                        class="good_name overflow_ellipsis_two"
                        @click="goGoodsDetail(goodItem.skuCode)"
                      >
                        <span class="titleColor"> 商品数量</span>：<span
                          class="fontStyle"
                          >{{ orderItem.goodsNum }}件
                        </span>
                      </div>
                      <div
                        v-if="orderItem.state != 0 && orderItem.state !== 3"
                        class="good_name overflow_ellipsis_two"
                        @click="goGoodsDetail(goodItem.skuCode)"
                      >
                        <span class="titleColor">待报价</span>：<span
                          class="fontStyle"
                          >{{ orderItem.toBeQuoted }}
                          件
                        </span>
                      </div>
                      <div
                        v-if="orderItem.state != 0 && orderItem.state !== 3"
                        class="good_name overflow_ellipsis_two"
                        @click="goGoodsDetail(goodItem.skuCode)"
                      >
                        <span class="titleColor">已报价</span>：<span
                          class="fontStyle"
                          >{{ orderItem.haveQuoted }}
                          件
                        </span>
                      </div>
                      <div
                        v-if="orderItem.state != 0"
                        class="good_name overflow_ellipsis_two"
                        @click="goGoodsDetail(goodItem.skuCode)"
                      >
                        <span class="titleColor">已下单</span>：<span
                          class="fontStyle"
                          >{{ orderItem.placeAnOrder }}
                          件
                        </span>
                      </div>
                      <div
                        v-if="orderItem.closed"
                        class="good_name overflow_ellipsis_two"
                        @click="goGoodsDetail(goodItem.skuCode)"
                      >
                        <span class="titleColor">已关闭</span>：<span
                          class="fontStyle"
                          >{{ orderItem.closed }}
                          件
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="after flex_row_center_center">--</div> -->
              <div class="order_price flex_row_center_center">
                <div v-if="orderItem.state == 0" class="yellow fontStyle">
                  待报价
                </div>
                <div v-if="orderItem.state == 1" class="green fontStyle">
                  报价中
                </div>
                <div v-if="orderItem.state == 2" class="bule fontStyle">
                  已报价
                </div>
                <div v-if="orderItem.state == 3" class="gray fontStyle">
                  已关闭
                </div>
              </div>
              <div class="oprate flex_column_center_center">
                <!-- 待付款订单可以立即支付 -->

                <!--<span
                  @click="
                    toDetail(
                      orderItem.planCode,
                      orderItem.state,
                      orderItem.isFillIn,
                      orderItem.goodsNum
                    )
                  "
                  style="cursor: pointer"
                  >查看</span>-->

                <router-link
                  target="_blank"
                  :to="{
                    path: '/member/procurement/waitQuotationDetail',
                    query: {
                      planCode: orderItem.planCode,
                      goodsNum: orderItem.goodsNum,
                      pageNum: param.pageNum,
                      pageSize: param.pageSize,
                    },
                  }"
                  v-if="orderItem.isFillIn == 0"
                >
                  <span style="color: #000">查看</span>
                </router-link>
                <router-link
                  target="_blank"
                  :to="{
                    path: '/member/procurement/procuremmentPlanDetail',
                    query: {
                      planCode: orderItem.planCode,
                      goodsNum: orderItem.goodsNum,
                      pageNum: param.pageNum,
                      pageSize: param.pageSize,
                    },
                  }"
                  v-if="orderItem.isFillIn != 0"
                >
                  <span style="color: #000">查看</span>
                </router-link>
              </div>
            </div>
          </div>
          <el-pagination
            style="margin-top: 10px"
            :vi-if="param.total != 0"
            v-model:current-page="param.pageNum"
            v-model:page-size="param.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            :small="small"
            :disabled="disabled"
            :background="background"
            layout="total, sizes, prev, pager, next, jumper"
            :total="param.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import {
  ElInput,
  ElMessage,
  ElMessageBox,
  ElTimeline,
  ElTimelineItem,
} from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import download from "downloadjs";
import { platform } from "@/utils/config";

export default {
  name: "order-list",
  components: {
    ElInput,
    ElTimeline,
    ElTimelineItem,
    SldCommonEmpty,
    MemberTitle,
  },
  setup() {
    // const balance = require("../../assets/buy/balance.png");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const memberInfo = reactive({ data: store.state.memberInfo });
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const current_state = ref(""); //当前列表订单状态
    const evaluate_state = ref(0); //是否是待评价
    const ruleFormRef = ref();
    const orderSn = ref(""); //订单id
    let orderProductObj = reactive({});
    let orderProductList = reactive({ data: [] }); //确认收货展示数据
    const tableData = reactive({ data: [] });

    const param = reactive({
      memberId: store.state.memberInfo.memberId,
      planCode: "",
      source: "",
      prayName: "",
      time: "",
      startDate: "",
      endDate: "",
      resource: "",
      state: "",
      bizBillCode: "",
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });

    const pageData = reactive({
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });
    const onSubmit = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          param.startDate = formEl.model.time[0];
          param.endDate = formEl.model.time[1];
          const paramss = { ...param };

          paramss.time = "";

          param.time = formEl.model.time;
          if (paramss.source && paramss.source == 1) {
            paramss.source = platform;
          }
          getOrderList(paramss);
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const toDetail = (code, state, isFillIn, goodsNum) => {
      console.log(code);
      if (isFillIn == 0) {
        router.push({
          path: "/member/procurement/waitQuotationDetail",
          query: {
            planCode: code,
            state: state,
            goodsNum: goodsNum,
            pageNum: param.pageNum,
            pageSize: param.pageSize,
          },
        });
      } else {
        router.push({
          path: "/member/procurement/procuremmentPlanDetail",
          query: {
            planCode: code,
            state: state,
            goodsNum: goodsNum,
            pageNum: param.pageNum,
            pageSize: param.pageSize,
          },
        });
      }
      //router.push({
      //  path: "/member/procurement/procuremmentPlanDetail",
      //  query: {
      //    planCode: code,
      //    state: state,
      //  },
      //});
    };
    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
      param.pageNum = 1;
      param.time = "";
      param.startDate = "";
      param.endDate = "";
      getOrderList(param);

      //param.resetFields()
      //param.planCode= "",
      // param. source= "",
      // param. prayName= "",
      // param. startDate= "",
      // param. endDate= "",
      //  param.resource= "",
      //  param.state= "",
      //getOrderList()
    };

    const getOrderList = (params) => {
      console.log(params);
      proxy.$get("api/shopp/purchase/plan/page", params).then((res) => {
        //原接口：v3/business/front/cart/cartList
        if (res.code == 200) {
          console.log(res.total);
          tableData.data = res.data.records;
          if (res.data.pageSize != 0) {
            param.pageSize = res.data.pageSize;
            param.pageNum = res.data.pageNum;
            param.total = res.data.total;
            console.log(param.total);
          } else {
            param.pageSize = 10;
            param.pageNum = 1;
            param.total = 0;
          }

          //cartLen.value = res.data.count ? res.data.count : 0
        }

        //cartLen.value = res.data.count ? res.data.count : 0
      });
    };
    const addPlan = () => {
      router.push("/member/procurement/addPlan");
    };
    const handleSizeChange = (val) => {
      param.pageSize = val;
      getOrderList(param);
      console.log(`${val} items per page`);
    };
    const handleCurrentChange = (val) => {
      param.pageNum = val;
      getOrderList(param);
      console.log(`current page: ${val}`);
    };
    onMounted(() => {
      if (route.query.orderState) {
        current_state.value = route.query.orderState;
      } else {
        current_state.value = "";
      }
      if (route.query.evaluateState) {
        evaluate_state.value = route.query.evaluateState;
      } else {
        evaluate_state.value = "";
      }
      //  getTime();
      console.log(route.query.pageNum, route.query.pageSize);
      if (route.query.pageNum && route.query.pageSize) {
        param.pageNum = route.query.pageNum;
        param.pageSize = route.query.pageSize;
      }
      getOrderList(param);
      //  getCancelList();
      //  getAddressList();
    });

    router.beforeEach((to, from, next) => {
      //  if (to.query.orderState) {
      //    current_state.value = to.query.orderState;
      //  } else {
      //    current_state.value = "";
      //  }
      //  if (to.query.evaluateState) {
      //    evaluate_state.value = to.query.evaluateState;
      //  } else {
      //    evaluate_state.value = "";
      //  }
      if (to.path == "/member/procurement/procurementPlanList") {
        getOrderList();
      }
      next();
    });
    return {
      L,

      getOrderList,
      onSubmit,
      param,
      tableData,
      toDetail,
      addPlan,
      resetForm,
      ruleFormRef,
      pageData,
      handleSizeChange,
      handleCurrentChange,
      platform,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/procurementPlanList.scss";
.sld_order_list .container {
  width: 1018px;
}
.el-pager li.active {
  color: $colorMain;
  cursor: default;
}
.look {
  color: rgb(74, 122, 211);
  cursor: pointer;
}
.el-pager li:hover {
  color: $colorMain;
}

.el-select-dropdown__item.selected {
  color: $colorMain;
}

.sld_order_list {
  color: #000;
  .sld_List {
    color: #000;
  }
  ::v-deep .el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    ::v-deep .el-dialog__headerbtn {
      top: auto;
    }
    ::v-deep .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
  ::v-deep .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
  ::v-deep.el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
  }
  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }
  .dia_btn {
    .el-button {
      width: 134px;
      height: 36px;
    }
  }
  .downLoad_contract {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8e8f1;
    border-radius: 5px;
    cursor: pointer;
  }
  .downLoad_contract:hover {
    border: 1px solid $colorMain;
  }
}
.container {
  .bootom-footer {
    text-align: right;
  }
  .bootom-footer button:first-child {
    margin-right: 10px;
  }
  // .el-input {
  //   width: 300px;
  // }
  .unit {
    font-size: 18px;
    margin-left: 10px;
  }
}
.demo-form-inline .el-input {
  --el-input-width: 220px;
}
.el-table thead {
  color: #5f5f60;
}
.el-table {
  color: #2b2b2c;
}
.sld_order_button {
  margin-bottom: 10px;
}
.el-radio {
  margin-right: 0 !important;
}
.button {
  background: #0e3177;
  cursor: pointer;
  width: 70px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  /*margin-left: 10px;*/
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
}
.button2 {
  background: #0e3177;
  cursor: pointer;
  width: 70px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  /*margin-left: 10px;*/
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
}
.headerSearch3 {
  position: relative;
}

.searchBtn {
  cursor: pointer;
  display: flex;
  width: 200px;
  position: absolute;
  top: 0;
  left: 85%;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  /*background: #03499e;*/
}
::v-deep .el-date-editor .el-range-separator {
  width: 10%;
}
.el-select .el-input.is-focus .el-input__inner,
.el-select .el-input__inner:focus {
  border-color: #409eff !important;
}
/*.member_info_container{
   padding: 0 0 0 0 !important;
}*/
/*.el-input {
  width: 210px !important;
}*/
</style>